import aditya from "../assets/board/aditya.jpg";
import anaadhi from "../assets/board/anaadhi.jpg";
import ansh from "../assets/board/ansh.jpg";
import anuj from "../assets/board/anuj.jpg";
import arnav from "../assets/board/arnav.jpg";
import ashwin from "../assets/board/ashwin.jpg";
import atharva from "../assets/board/atharva.jpg";
import ayush from "../assets/board/ayush.jpg";
import jahnavi from "../assets/board/jahnavi.jpg";
import namrata from "../assets/board/namrata.jpg";
import pragz from "../assets/board/pragz.jpg";
import pratham from "../assets/board/pratham.jpg";
import yaseen from "../assets/board/yaseen.jpg";

const Team = {
	board: [
		{
			name: "Anuj Kamath",
			pos: "Chairperson",
			gh: "",
			li: "https://www.linkedin.com/in/anuj-kamath-0a1ab6131/",
			image: anuj,
			dr: "",
		},
		{
			name: "Pragadeeshwar Kannan",
			pos: "Vice Chairperson",
			gh: "",
			li: "https://in.linkedin.com/in/pragadeeshwar-kannan-5a216217b",
			image: pragz,
			dr: "",
		},
		{
			name: "Aprameya Ansh",
			pos: "General Secretary and Treasurer",
			gh: "",
			li: "https://www.linkedin.com/in/aprameya-ansh-024948284",
			image: ansh,
			dr: "",
		},
		{
			name: "Ayush Singh",
			pos: "Design Head",
			gh: "",
			li: "https://www.linkedin.com/in/ayush-singh-67b65b290",
			image: ayush,
			dr: "",
		},
		{
			name: "Pratham Shetty",
			pos: "Tech Head",
			gh: "",
			li: "https://www.youtube.com/watch?v=xvFZjo5PgG0",
			image: pratham,
			dr: "",
		},
		{
			name: "Ashwin Mittal",
			pos: "Tech Head",
			gh: "",
			li: "https://www.linkedin.com/in/ashwin-mittal03/",
			image: ashwin,
			dr: "",
		},
		{
			name: "Yaseen Shaikh",
			pos: "ML Head",
			gh: "",
			li: "https://www.linkedin.com/in/yaseen-shaikh-221558243",
			image: yaseen,
			dr: "",
		},
		{
			name: "Anaadhi Mongia",
			pos: "ML Head",
			gh: "",
			li: "https://www.youtube.com/watch?v=xvFZjo5PgG0",
			image: anaadhi,
			dr: "",
		},
		{
			name: "Arnav Jagia",
			pos: "Dev Head",
			gh: "",
			li: "https://linkedin.com/in/arnavjagia",
			image: arnav,
			dr: "",
		},
		{
			name: "Aditya Verma",
			pos: "Dev Head",
			gh: "",
			li: "https://www.linkedin.com/in/adiv933/",
			image: aditya,
			dr: "",
		},
		{
			name: "Namrata Raj",
			pos: "Project Head",
			gh: "",
			li: "https://www.linkedin.com/in/namrataraj",
			image: namrata,
			dr: "",
		},
		{
			name: "Jahnavi Singh",
			pos: "Publicity and Logistics Head",
			gh: "",
			li: "https://www.linkedin.com/in/jahnavi-singh-m15",
			image: jahnavi,
			dr: "",
		},
		{
			name: "Atharva Mishra",
			pos: "Head of Human Resources",
			gh: "",
			li: "https://www.youtube.com/watch?v=xvFZjo5PgG0",
			image: atharva,
			dr: "",
		}
	],


	mancomm: [
		"Abhinav Chauhan",
		"Abhishek Jayant Holla",
		"Adithya M Adiga",
		"Aditi Gupta",
		"Aditya Anand Baranwal",
		"Aditya Nigam",
		"Aditya Sinha",
		"Akash Shaw",
		"Akshat Pandey",
		"Aman Garg",
		"Ananya Sharma",
		"Animesh Kumar",
		"Annanya Tayal",
		"Arnav Kiran Shetty",
		"Arushi Anand Kumar",
		"Aryan",
		"Aryan Kudva",
		"Avikshit Dhar",
		"Avinash Binand",
		"Ayan",
		"Ayesha Afzal Ali",
		"Charitha A Gowda",
		"Anshul Shukla",
		"Deepraj Bhattacharjee",
		"Devarsh Shah",
		"Dhruv Bajaj",
		"Dhruva Goyal",
		"Diptarka Nandi",
		"Harkaran Singh Anand",
		"Harsha N K",
		"Harshith Velkumar",
		"Hitansh Agarwal",
		"Hiten Raj Singh",
		"Hrithiq Gupta",
		"Jayasree Madireddy",
		"Dhruv Saraogi",
		"Kunal Sahni",
		"Kashyap Datta",
		"Konthalapalli Laalenthika",
		"Manas Goel",
		"Mayur R Das",
		"Mehul Kaushik",
		"Mohammad Tausif",
		"Mugdha Chatterjee",
		"Nandini Neelesh",
		"Nimit Jain",
		"Nitin Kumar",
		"Param Mehta",
		"Lakshya Jain",
		"Prakhar Umrao",
		"Pranjal Mathew Lobo",
		"Prasanna",
		"Nisarg Shukla",
		"Pulkit",
		"Pushkar Ojha",
		"Riddhima Chauhan",
		"Riya Agarwal",
		"Sarvani Konakanchi",
		"Sandeep Balaji",
		"Sankalp Bajpai",
		"Satyam Saraf",
		"Shreehari V Bhat",
		"Preksha K",
		"Shreya Agarwal",
		"Siddhant Chutke",
		"Siddharth Mehta",
		"Smeet Pancholi",
		"Surabhi Sanjay",
		"Tanmay Sharma",
		"Tanvi Badkar",
		"Tejasveer Singh",
		"Thanayy",
		"Uday Todi",
		"Umair Suleman",
		"Urja Srivastava",
		"Vaidheesh Bharadwaj",
		"Vansh Odhrani",
		"Yash Chauhan",
		"Yashika Agarwal",
		"Yukti Bhatia",
		"Soumya Shrivastav",

	]
};

export default Team;
